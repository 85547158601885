import { useTranslation } from 'react-i18next';
import { Form, IconButton, Icons, TextInput } from '@lib/ui';

export type TextInputProps = {
  id: string;
  defaultValue?: string;
  value?: string;
  error?: string;
  label?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
};

export function FilterTextInput(props: TextInputProps) {
  const { t } = useTranslation();
  const {
    defaultValue,
    id,
    error,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    onClear,
    onFocus,
  } = props;

  return (
    <Form.Field name={id}>
      <Form.Control asChild>
        <TextInput.Root
          hasError={!!error}
          size="sm"
          variant="dark"
          fill="sheer"
        >
          <TextInput.Container>
            {label ? <TextInput.Label id={id}>{label}</TextInput.Label> : null}
          </TextInput.Container>
          <TextInput.Input
            id={id}
            name={id}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          >
            <TextInput.Adornment align="end">
              {value ? (
                <IconButton
                  iconSize="16"
                  label={t('Clear input')}
                  icon={<Icons.CloseIcon aria-hidden />}
                  size="xs"
                  fill="none"
                  onClick={onClear}
                  variant="subtle"
                />
              ) : null}
            </TextInput.Adornment>
          </TextInput.Input>
        </TextInput.Root>
      </Form.Control>
      {error ? <Form.Error>{error}</Form.Error> : null}
    </Form.Field>
  );
}
