import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tiptap/react';
import clsx from 'clsx';
import { Button, Icons, Popover } from '@lib/ui';
import * as styles from './toolbar.css';

const toolbarHeadings = [
  { value: 'p', icon: null, title: 'Paragraph' },
  { value: 'h1', icon: <Icons.Header1Icon aria-hidden />, title: 'Heading 1' },
  { value: 'h2', icon: <Icons.Header2Icon aria-hidden />, title: 'Heading 2' },
  { value: 'h3', icon: <Icons.Header3Icon aria-hidden />, title: 'Heading 3' },
];

type Props = {
  editor: Editor;
};

export const ToolbarHeadings = forwardRef<HTMLDivElement, Props>(
  ({ editor }, ref) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const onHeadingChange = useCallback(
      (value: string) => {
        if (!editor) {
          return;
        }
        switch (value) {
          case 'p':
            editor.chain().focus().setParagraph().run();
            break;
          case 'h1':
            editor.chain().focus().setHeading({ level: 1 }).run();
            break;
          case 'h2':
            editor.chain().focus().setHeading({ level: 2 }).run();
            break;
          case 'h3':
            editor.chain().focus().setHeading({ level: 3 }).run();
            break;
        }
        setIsOpen(false);
      },
      [editor]
    );

    return (
      <Popover.Root>
        <Popover.Anchor ref={ref} />
        <Popover.Trigger asChild>
          <Button
            fill="none"
            variant="light"
            size="xs"
            adornmentEnd={
              <Icons.ChevronDownIcon aria-label={t('select heading')} />
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            {getCurrentHeadingTitle(editor)}
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            align="start"
            className={clsx(styles.popoverContent, styles.headingsContent)}
          >
            {toolbarHeadings.map((item) => (
              <div key={item.value} onClick={() => onHeadingChange(item.value)}>
                {item.icon} {item.title}
              </div>
            ))}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }
);

function getCurrentHeading(editor: Editor) {
  if (editor.isActive('heading', { level: 1 })) {
    return 'h1';
  }
  if (editor.isActive('heading', { level: 2 })) {
    return 'h2';
  }
  if (editor.isActive('heading', { level: 3 })) {
    return 'h3';
  }
  return 'p';
}

function getCurrentHeadingTitle(editor: Editor) {
  const heading = getCurrentHeading(editor);
  const headingItem = toolbarHeadings.find((item) => item.value === heading);
  return headingItem ? headingItem.title : 'Paragraph';
}
